// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-forms-js": () => import("./../src/templates/forms.js" /* webpackChunkName: "component---src-templates-forms-js" */),
  "component---src-templates-review-js": () => import("./../src/templates/review.js" /* webpackChunkName: "component---src-templates-review-js" */),
  "component---src-templates-legal-js": () => import("./../src/templates/legal.js" /* webpackChunkName: "component---src-templates-legal-js" */),
  "component---src-templates-instructions-page-js": () => import("./../src/templates/instructionsPage.js" /* webpackChunkName: "component---src-templates-instructions-page-js" */),
  "component---src-templates-instructions-js": () => import("./../src/templates/instructions.js" /* webpackChunkName: "component---src-templates-instructions-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

